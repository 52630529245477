import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const PostLink = styled(Link)`
  display: inline-block;
  box-shadow: none;
  border-bottom: 1px solid #eee;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: -1rem;
  width: calc(100% + 2rem);
  &:hover {
    background-color: #f8f8f7;
    background-image: none;
  }
`;

const PostContainer = styled.div`
  color: #444;
`;

const PostTitle = styled.h2`
  margin-top: 1rem;
`;

const PostDate = styled.small`
  display: block;
  margin-bottom: 0.5rem;
`;

const PostExcerpt = styled.p`
  margin-bottom: 0.5rem;
`;

const PostTimeToRead = styled.p`
  text-align: right;
`;

class BlogIndex extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead
      });
    });
    return postList;
  }
  render() {
    const postList = this.getPostList();
    return (
      <div>
        {/* Your post list here. */
        postList.map(post => (
          <PostLink to={post.path} key={post.title}>
            <PostContainer>
              <PostTitle>{post.title}</PostTitle>
              <PostDate>{post.date}</PostDate>
              <p dangerouslySetInnerHTML={{ __html: post.excerpt }} />
              <PostTimeToRead>{post.timeToRead} min read</PostTimeToRead>
            </PostContainer>
          </PostLink>
        ))}
      </div>
    );
  }
}

export default BlogIndex;
